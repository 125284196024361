import React from 'react';
import { useSelector } from 'react-redux';
import AuthStateProvider from '../components/authStateProvider';
import Header from '../components/header/header';
import Footer from '../components/footer';
import EditProfileForm from '../components/editProfileForm';
import SEO from '../components/SEO';

const EditProfile = (props) => {
  const auth = useSelector((state) => state.auth);

  return (
    <AuthStateProvider authRequired {...props}>
      <SEO title="Edit your profile" />
      <Header show />
      <EditProfileForm initValue={auth} />
      <Footer variant="post-login-footer" />
    </AuthStateProvider>
  );
};

export default EditProfile;

import { REMOTE_URL } from '../common/api';

export const validateCoupon = async (coupon) => {
  try {
    const response = await fetch(`${REMOTE_URL}/wp-json/rcp-coupon/code?coupon=${coupon}`);

    return response.json();
  } catch (error) {}
};

export const registerUser = async (data, token) => {
  try {
    const formdata = new FormData();
    formdata.append('username', data.username);
    formdata.append('email', data.email);
    formdata.append('password', data.password);
    formdata.append('first_name', data.first_name);
    formdata.append('last_name', data.last_name);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${REMOTE_URL}/wp-json/wp/v2/users`, requestOptions);

    return response.json();
  } catch (error) {}
};

export const getStripeClinetSecret = async (amount) => {
  try {
    const response = await fetch(`${REMOTE_URL}/wp-json/stripe/v1/order?charge=${amount}`);

    return response.json();
  } catch (error) {}
};

export const payWithCard = async (stripe, card, clientSecret, data) => {
  const name = `${data.first_name} ${data.last_name}`;
  const email = data.email;
  try {
    const response = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: email,
      payment_method: {
        card: card,
        billing_details: {
          name,
          email,
        },
      },
    });

    return response;
  } catch (error) {}
};

export const addPaidMembershipToUser = async (uid) => {
  try {
    const response = await fetch(`${REMOTE_URL}/wp-json/add_membership/user?user_id=${uid}`);

    return response.json();
  } catch (error) {}
};

export const validateUserNameEmail = async (label, val) => {
  try {
    const response = await fetch(`${REMOTE_URL}/wp-json/customapi/v2/user?${label}=${val}`);

    return response.json();
  } catch (error) {}
};

export const addUserToMembership = async (uid, sid) => {
  try {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    const response = await fetch(
      `${REMOTE_URL}/wp-json/add_membership/user?user_id=${uid}&subscription=${sid}`,
      requestOptions
    );

    return response.json();
  } catch (error) {}
};

export const updateUserInfo = async (uid, data, supertoken) => {
  try {
    const formdata = new FormData();
    formdata.append('password', data.rePassword);
    formdata.append('first_name', data.firstName);
    formdata.append('last_name', data.lastName);

    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + supertoken,
      },
      body: formdata,
      redirect: 'follow',
    };

    const response = await fetch(`${REMOTE_URL}/wp-json/wp/v2/users/${uid}`, requestOptions);

    return response.json();
  } catch (error) {}
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import FormField from '../form/formField';
import './style.scss';
import { TYPE_LOCK_INTERRUPT_MINI_PLAYER, TYPE_UNLOCK_INTERRUPT_MINI_PLAYER } from '../../store/reducer';
import { updateUserInfo } from '../../actions/register';

const translations = {
  eng: {
    title: 'Edit your profile',
    displayName: 'Display name',
    password: 'Password',
    passAgain: 'New Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    submit: 'Save Changes',
  },
  esp: {
    title: 'Edite su perfil',
    displayName: 'Nombre para mostrar',
    password: 'Contraseña',
    passAgain: 'Nueva contraseña',
    firstName: 'Nombre',
    lastName: 'Apellidos',
    email: 'Correo electrónico',
    submit: 'Guardar cambios',
  },
};

const validation = yup.object({
  firstName: yup.string().required('First Name cannot be Empty'),
  lastName: yup.string().required(),
  displayName: yup.string().required(),
  email: yup.string().email('Email format is not correct!').required(),
  password: yup.string().required(),
  rePassword: yup
    .string()
    .required('New Password cannot be Empty')
    .notOneOf([yup.ref('password'), null], 'New Password cannot be same as before.'),
});

const EditProfileForm = (props) => {
  const { initValue } = props;
  const { auth, super_token, language } = useSelector((state) => state);
  const dispatch = useDispatch();

  const first_name = initValue?.first_name?.split(' ')[0] || '';
  const last_name = initValue?.first_name?.split(' ')[1] || '';

  useEffect(() => {
    dispatch({ type: TYPE_LOCK_INTERRUPT_MINI_PLAYER });

    return () => {
      dispatch({ type: TYPE_UNLOCK_INTERRUPT_MINI_PLAYER });
    };
  }, []);

  const handleUserUpdate = async (formData) => {
    const uid = auth.userId;
    const token = super_token;
    const res = await updateUserInfo(uid, formData, token);
  };

  return (
    <div className="edit-profile-container">
      <div className="edit-profile-inner">
        <h2 className="edit-profile-title fnt-evolventa">{translations[language].title}</h2>
        <Formik
          validationSchema={validation}
          onSubmit={(formData) => {
            handleUserUpdate(formData);
          }}
          initialValues={{
            firstName: first_name,
            lastName: last_name,
            displayName: initValue?.username,
            email: initValue?.email,
            password: '',
            rePassword: '',
          }}
        >
          {({ values, handleChange, handleSubmit }) => (
            <form className="profile-form" onSubmit={handleSubmit}>
              <FormField
                label={translations[language].displayName}
                required={false}
                className="mt-4"
                containerClass="profile-input"
                name="displayName"
                value={values.displayName}
                onChange={handleChange}
                disabled
              />
              <FormField
                label={translations[language].email}
                required={false}
                className="mt-4"
                containerClass="profile-input"
                name="email"
                value={values.email}
                onChange={handleChange}
                disabled
              />
              <ErrorMessage name="email" render={(err) => <p className="fnt-red fnt-poppins fnt-12">{err}</p>} />
              <FormField
                label={translations[language].firstName}
                required={false}
                className="mt-4"
                containerClass="profile-input"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
              />
              <ErrorMessage name="firstName" render={(err) => <p className="fnt-red fnt-poppins fnt-12">{err}</p>} />
              <FormField
                label={translations[language].lastName}
                required={false}
                className="mt-4"
                containerClass="profile-input"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
              />
              <ErrorMessage name="lastName" render={(err) => <p className="fnt-red fnt-poppins fnt-12">{err}</p>} />
              <FormField
                label={translations[language].password}
                required={false}
                className="mt-4"
                containerClass="profile-input"
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
              />
              <ErrorMessage name="password" render={(err) => <p className="fnt-red fnt-poppins fnt-12">{err}</p>} />
              <FormField
                label={translations[language].passAgain}
                required={false}
                className="mt-4"
                name="rePassword"
                type="password"
                containerClass="profile-input"
                value={values.rePassword}
                onChange={handleChange}
              />
              <ErrorMessage name="rePassword" render={(err) => <p className="fnt-red fnt-poppins fnt-12">{err}</p>} />
              <button
                className="fnt-r-14 no-border fnt-weight-600 bg-prime fnt-white fnt-poppins pt-2 pb-2 pl-4 pr-4 profile-submit"
                type="submit"
              >
                {translations[language].submit}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default EditProfileForm;
